import { ProductHead } from "@/components/Product/components/ProductHead";
import { ProductLink } from "@/components/Product/components/ProductLink";
import { ExtrasProductCardInterface } from "@/components/Product/ProductCard";

export const RightAlignment = ({ ...props }: ExtrasProductCardInterface) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-x-5 w-full md:block hidden">
        <div className="col-span-6 md:col-span-12">
          <ProductHead {...props} />
        </div>
        <div className="col-span-6 md:col-span-12">
          <ProductLink {...props} className="!w-full" />
        </div>
      </div>

      <div className="flex flex-row gap-x-5 w-full justify-center md:hidden block items-end">
        <div className="flex-1">
          <ProductHead {...props} className={props.className} />
        </div>
        <div className="flex-1">
          <ProductLink {...props} className="!w-full" />
        </div>
      </div>
    </>
  );
};
