"use client";

import { ProductVariant } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import Link from "next/link";
import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { useBasePrice } from "@/hook/product";
import useProductPrice from "@/lib/hooks/use-product-price";

interface Props {
  product: PricedProduct;
  variant?: ProductVariant;
  testId?: string;
  boldText?: boolean;
  smallText?: boolean;
  className?: string;
  mwstClass?: string;
  bestPrice?: boolean;
  basePrice?: boolean;
  showShippingNote?: boolean;
}

export default function ProductPrice({
  product,
  variant: selectedVariant,
  testId,
  boldText,
  smallText,
  className,
  mwstClass,
  bestPrice = true,
  basePrice = true,
  showShippingNote,
}: Props) {
  const getBasePrice = useBasePrice();
  const price = useProductPrice({ id: product.id, variantId: selectedVariant?.id, product: product });
  const [productBasePrice, setProductBasePrice] = React.useState<string | null>(null);

  const selectedPrice = useMemo(() => {
    const { variantPrice, cheapestPrice } = price;

    return variantPrice || cheapestPrice || null;
  }, [price]);

  React.useEffect(() => {
    if (selectedVariant && price.variant) {
      setProductBasePrice(getBasePrice(selectedVariant, price.variant.calculated_price));
    }
  }, [getBasePrice, price.variant, selectedVariant]);

  return (
    <>
      <div className={className || "text-2xl font-semibold mb-1"}>
        {!selectedPrice ? (
          <>
            <Skeleton width={63} inline={true} />
            {" €"}
          </>
        ) : (
          <div className={`${boldText ? "font-bold" : ""} ${!smallText ? "text-2xl" : "text-xl"}`} data-testid={`${testId}DetailsSellingPrice${product.id}`}>
            {selectedPrice.price_type === "sale" && (
              <>
                <span className={"text-primary font-black"} data-testid={`${testId}DetailsSalePriceCalculated${product.id}`}>
                  {selectedPrice.calculated_price}
                </span>
                <s className="text-black text-sm font-bold ml-3 mr-3" data-testid={`${testId}DetailsSellingPriceOriginal${product.id}`}>
                  {selectedPrice.original_price}
                </s>
                <div className="inline-block text-sm lg:text-base text-primary font-black" data-testid={`${testId}DetailsSalePercentage${product.id}`}>
                  -{selectedPrice.percentage_diff}%
                </div>
              </>
            )}
            {selectedPrice.price_type !== "sale" && (
              <span
                className={`font-bold ${!smallText ? "text-3xl" : "text-xl"} ${className ?? ""}`}
                data-testid={`${testId}DetailsSellingPriceCalculated${product.id}`}
              >
                {selectedPrice.calculated_price}
              </span>
            )}
          </div>
        )}
      </div>
      {(basePrice || showShippingNote) && (
        <p className={`text-sm ${mwstClass ?? ""}`}>
          {basePrice && productBasePrice}
          {showShippingNote && (
            <>
              inkl. MwSt zzgl. <Link href={"/versand-lieferung"}>Versand</Link>
            </>
          )}
        </p>
      )}
      {bestPrice && selectedPrice?.best_price && <p className={`text-sm font-bold`}>Bestpreis der letzten 30 Tage: {selectedPrice.best_price}</p>}
    </>
  );
}

/**
 * TODO: Mit <ProductPrice> zusammenführen. Aber aktuell haben beide Ansichten noch unterschiedliche Gestaltungen, daher viel copy&paste
 */
export function ProductCardPrice({
  product,
  variant: _selectedVariant,
  testId,
  boldText,
  smallText,
  // className,
  // mwstClass,
  bestPrice = true,
  basePrice = true,
}: Props) {
  const getBasePrice = useBasePrice();
  const price = useProductPrice({ id: product.id, variantId: _selectedVariant?.id, product: product });

  const selectedPrice = useMemo(() => {
    const { variantPrice, cheapestPrice } = price;

    return variantPrice || cheapestPrice || null;
  }, [price]);
  const selectedVariant = price.variant || price.cheapestVariant;
  const productBasePrice = selectedPrice?.calculated_price && selectedVariant ? getBasePrice(selectedVariant, selectedVariant.calculated_price) : null;

  if (!selectedPrice) {
    return (
      <div className={`px-1 ${!smallText ? "pt-4" : "pt-0"} pb-2`}>
        <div
          className={`${boldText ? "font-bold" : ""} ${!smallText ? "text-sm lg:text-2xl" : "text-xl"}`}
          data-testid={`${testId}DetailsSellingPrice${product.id}`}
        >
          <Skeleton width={63} inline={true} />
          {" €"}
        </div>
      </div>
    );
  }
  return (
    <div className={`px-1 ${!smallText ? "pt-4" : "pt-0"} pb-2`}>
      <div
        className={`${boldText ? "font-bold" : ""} ${!smallText ? "text-sm lg:text-2xl" : "text-xl"}`}
        data-testid={`${testId}DetailsSellingPrice${product.id}`}
      >
        {selectedPrice && selectedPrice.price_type === "sale" && (
          <>
            <span className={"text-primary font-black"} data-testid={`${testId}DetailsSalePriceCalculated${product.id}`}>
              {selectedPrice.calculated_price}
            </span>
            <s className="text-black text-sm font-bold ml-3 mr-3" data-testid={`${testId}DetailsSellingPriceOriginal${product.id}`}>
              {selectedPrice.original_price}
            </s>
            <div className="inline-block text-sm lg:text-base text-primary font-black" data-testid={`${testId}DetailsSalePercentage${product.id}`}>
              -{selectedPrice.percentage_diff} %
            </div>
          </>
        )}
        {selectedPrice && selectedPrice.price_type !== "sale" && (
          <p className={`font-bold ${!smallText ? "text-sm lg:text-2xl" : "text-xl"}`} data-testid={`${testId}DetailsSellingPriceCalculated${product.id}`}>
            {selectedPrice.calculated_price}
          </p>
        )}
      </div>
      {basePrice && <p className="text-xs">{productBasePrice}</p>}
      {bestPrice && selectedPrice?.best_price && <p className={`text-xs font-bold`}>Bestpreis der letzten 30 Tage: {selectedPrice.best_price}</p>}
    </div>
  );
}
