import { ProductHead } from "@/components/Product/components/ProductHead";
import { ProductLink } from "@/components/Product/components/ProductLink";
import { ExtrasProductCardInterface } from "@/components/Product/ProductCard";

export const BottomAlignment = ({ ...props }: ExtrasProductCardInterface) => {
  return (
    <>
      <ProductHead {...props} />
      <ProductLink {...props} />
    </>
  );
};
