"use client";

import { LineItem } from "@medusajs/medusa";
import { useMutation } from "@tanstack/react-query";
import { useCart, useMedusa } from "medusa-react";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useAccount } from "@/lib/contexts/account-context";

interface AddToWishlistData {
  id: string;
  product_id: string;
  variant_id?: string;
}

interface RemoveFromWishlistData {
  id: string;
  product_id: string;
  variant_id?: string;
}

interface Wishlist {
  object: string;
  id: string;
  created_at: string;
  updated_at: string;
  region_id: string;
  customer_id: string;
  items: WishlistItem[];
}

type WishlistItem = LineItem;

interface WishlistContext {
  wishlist?: Wishlist;
  isLoading: boolean;
  isMutating: boolean;
  addProduct: (data: AddToWishlistData) => void;
  removeProduct: (data: RemoveFromWishlistData) => void;
  onAddedToWishlist?: () => void;
  onRemovedFromWishlist?: () => void;
}

const WishlistContext = createContext<WishlistContext | null>(null);

interface WishlistProviderProps {
  children?: React.ReactNode;
}

export const WishlistProvider = ({ children }: WishlistProviderProps) => {
  const { customer, retrievingCustomer } = useAccount();
  const { cart } = useCart();
  const { client } = useMedusa();
  const [wishlist, setWishlist] = useState<Wishlist>();
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (customer?.id && cart?.region) {
      client.client.request("GET", `/store/wishlist/customer/${customer?.id}/region/${cart?.region?.id}`).then((res) => {
        setWishlist(res);
        setIsLoading(false);
      });
    }
  }, [customer, setWishlist, cart?.region]);

  useEffect(() => {
    if (retrievingCustomer) {
      setIsLoading(true);
    }
  }, [retrievingCustomer]);

  const addToWishlistMutation = useMutation(({ ...data }: AddToWishlistData) => client.client.request("POST", `/store/wishlistItem/variant`, data), {
    onMutate: () => {
      setIsMutating(true);
    },
    onSuccess: (wishlist) => {
      setWishlist(wishlist);
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  // @todo: wenn vairant_id in data vorhanden ist, auch die Route "/store/wishlistItem/variant" nehmen
  const removeFromWishlistMutation = useMutation(({ ...data }: RemoveFromWishlistData) => client.client.request("DELETE", `/store/wishlistItem`, data), {
    onMutate: () => {
      setIsMutating(true);
    },
    onSuccess: (wishlist) => {
      setWishlist(wishlist);
    },
    onSettled: () => {
      setIsMutating(false);
    },
  });

  return (
    <WishlistContext.Provider
      value={{
        addProduct: addToWishlistMutation.mutate,
        removeProduct: removeFromWishlistMutation.mutate,
        wishlist,
        isLoading,
        isMutating,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => {
  const context = useContext(WishlistContext);

  if (context === null) {
    throw new Error("useWishlist must be used within a WishlistProvider");
  }
  return context;
};
