import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { WishlistIcon } from "@/components/Wishlist/WishlistIcon";
import { PricedVariantWithImage, ProductPreviewType } from "@/types/global";

type Props = {
  product: ProductPreviewType;
  testId?: string;
};

const getVariantOptions = (variants: PricedVariantWithImage[]) => {
  const options: string[] = [];
  const elements = [];
  for (const variant of Symbol.iterator in Object(variants) ? variants : []) {
    if (variant?.metadata?.filter_color !== undefined && variant.thumbnail !== null && options.indexOf(variant.metadata.filter_color as string) === -1) {
      options.push(variant.metadata.filter_color as string);
      elements.push({ option: variant.metadata.filter_color as string, thumbnail: variant.thumbnail as string });
    }
  }
  return elements;
};

const ProductVariantLinks: React.FC<Props> = ({ product, testId }) => {
  const options: { option: string; thumbnail: string }[] = getVariantOptions(product.variants as PricedVariantWithImage[]);
  const productDetailLink = `/p/${product.handle}`;
  return (
    <>
      <div
        className={clsx("z-10 absolute bottom-3 right-2 cursor-pointer", {
          "lg:group-hover:bottom-[38%] xl:group-hover:bottom-[22%] ease-in-out duration-150 transition-all": options.length > 1,
        })}
        data-testid={`${testId}Icons${product.id}`}
      >
        <WishlistIcon product={product} />
      </div>
      {options.length > 1 && (
        <div
          className={
            "bg-white absolute left-0 right-0 bottom-0 top-full lg:group-hover:top-[65%] xl:group-hover:top-[80%] z-20 ease-in-out duration-150 transition-all overflow-hidden overflow-x-auto scrolling-touch leading-[65px] whitespace-nowrap"
          }
        >
          <div className={"p-2"}>
            {options.map((option, key) => (
              <Link href={productDetailLink + "?option=" + option.option} key={key}>
                <Image
                  src={option.thumbnail}
                  title={option.option}
                  alt={option.option}
                  className={"border-gray-200 border text-xsmall-regular min-h-[50px] inline-block mr-2 hover:border-gray-900"}
                  width={50}
                  height={0}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductVariantLinks;
