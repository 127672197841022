"use client";

import { useCart, useProducts } from "medusa-react";
import React, { useMemo } from "react";

import { ProductCardContent, ProductLayout } from "@/components/Product/components/ProductCardLayout";
import { PlaceholderImageString } from "@/components/Product/ProductImage";
import ProductCardSkeleton from "@/components/Skeleton/Product/Card";
import { StrapiProductInterface } from "@/interfaces/Api/Product/ProductInterface";
import transformProductPreview from "@/lib/util/transform-product-preview";
import { PricedVariantWithImage, ProductPreviewType } from "@/types/global";

export enum PRODUCT_TEXT_ALIGNMENT {
  BOTTOM,
  RIGHT,
}

export interface ProductCardInterface {
  product: ProductPreviewType;
  className?: string;
  onClick?: () => void;
  boldText?: boolean;
  smallText?: boolean;
  testId?: string;
  priority?: boolean;
  alignment?: PRODUCT_TEXT_ALIGNMENT;
}

export interface ExtrasProductCardInterface extends ProductCardInterface {
  productThumbnail: string;
  productDetailLink: string;
  isProductList?: boolean;
  isSlider?: boolean;
  forceFullHeight?: boolean;
  gridCols?: number;
  maxHeight?: number | boolean;
  testId: string;
}

interface ProvidedProductCardInterface {
  product: StrapiProductInterface;
  className?: string;
  alignment?: PRODUCT_TEXT_ALIGNMENT;
  isSlider?: boolean;
  isProductList?: boolean;
  forceFullHeight?: boolean;
  gridCols?: number;
  maxHeight?: number | boolean;
  onClick?: () => void;
}

export default function ProductCard(
  props: ProductCardInterface & {
    testId?: string;
    isProductList?: boolean;
    isSlider?: boolean;
    forceFullHeight?: boolean;
    gridCols?: number;
    maxHeight?: number | boolean;
  },
) {
  const testId = props.testId ?? "placeholder";
  const alignment = props.alignment ?? PRODUCT_TEXT_ALIGNMENT.BOTTOM;

  const thumbnailImage = useMemo(() => {
    if (!props.product.preferred_variant) {
      return props.product.thumbnail;
    }

    const variant: PricedVariantWithImage | undefined = props.product.variants.find((v) => v.id === props.product.preferred_variant);
    return variant?.thumbnail ?? props.product.thumbnail;
  }, [props.product]);

  return (
    <ProductLayout {...props} testId={testId} alignment={alignment}>
      <ProductCardContent
        productThumbnail={thumbnailImage ?? PlaceholderImageString}
        productDetailLink={`/p/${props.product.handle}`}
        testId={testId}
        isProductList={props.isProductList}
        isSlider={props.isSlider}
        forceFullHeight={props.forceFullHeight}
        gridCols={props.gridCols}
        maxHeight={props.maxHeight}
        {...props}
      />
    </ProductLayout>
  );
}

export const ProvidedProductCard = ({
  product,
  className,
  alignment = PRODUCT_TEXT_ALIGNMENT.BOTTOM,
  isProductList,
  isSlider,
  forceFullHeight,
  gridCols,
  maxHeight,
  onClick,
}: ProvidedProductCardInterface) => {
  const { cart } = useCart();
  const { products } = useProducts({ id: [product?.attributes?.medusa_id], cart_id: cart?.id }, { enabled: !!cart?.id && !!product?.attributes?.medusa_id });

  if (!products?.length || !cart?.region_id) {
    return <ProductCardSkeleton />;
  }

  return (
    <ProductCard
      isProductList={isProductList}
      isSlider={isSlider}
      forceFullHeight={forceFullHeight}
      gridCols={gridCols}
      maxHeight={maxHeight}
      alignment={alignment}
      product={transformProductPreview(products[0], cart.region)}
      onClick={onClick}
      className={className}
    />
  );
};
