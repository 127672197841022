import { Product, Region } from "@medusajs/medusa";
import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import { formatAmount } from "medusa-react";

import { getPercentageDiff } from "@/lib/util/get-precentage-diff";
import { Brand } from "@/lib/util/gtm";
import { ProductPreviewType } from "@/types/global";
import { CalculatedVariant } from "@/types/medusa";

type VariantWithPriceAndImages = CalculatedVariant & {
  thumbnail?: string;
  images?: Product["images"];
};

const transformProductPreview = (product: PricedProduct & { preferred_variant?: string }, region: Region): ProductPreviewType => {
  const variants = product.variants as unknown as VariantWithPriceAndImages[];
  const brand = (product.metadata?.brand as Brand)?.name ?? null;
  const productTypeDetail = (product.metadata?.product_type_detail as string) ?? null;
  const metadata = { ...product.metadata };

  let cheapestVariant = "preferred_variant" in product ? variants.find((variant) => variant.id === product.preferred_variant) : undefined;

  if (!cheapestVariant && variants?.length > 0) {
    cheapestVariant = variants.reduce((acc, curr) => {
      if (acc.calculated_price > curr.calculated_price) {
        return curr;
      }
      return acc;
    }, variants[0]);
  }

  if (cheapestVariant) {
    metadata.content = cheapestVariant.metadata?.content as string;
    metadata.content_unit = cheapestVariant.metadata?.content_unit as string;
  }

  return {
    ...product,
    brand,
    product_type_detail: productTypeDetail,
    thumbnail: cheapestVariant?.thumbnail ?? product.thumbnail,
    images: cheapestVariant?.images ?? product.images,
    price: cheapestVariant
      ? {
          calculated_price: formatAmount({
            amount: cheapestVariant.calculated_price,
            region: region,
            includeTaxes: false,
            locale: "de-DE",
          }),
          original_price: formatAmount({
            amount: cheapestVariant.original_price,
            region: region,
            includeTaxes: false,
            locale: "de-DE",
          }),
          difference: getPercentageDiff(cheapestVariant.original_price, cheapestVariant.calculated_price),
          difference_price: parseFloat(((cheapestVariant.original_price - cheapestVariant.calculated_price) / 100).toFixed(2)),
          price_type: cheapestVariant.calculated_price_type,
        }
      : undefined,
    metadata,
  };
};

export default transformProductPreview;
