import clsx from "clsx";
import Link from "next/link";
import React from "react";

import { ExtrasProductCardInterface } from "@/components/Product/ProductCard";

import { ProductCardPrice } from "../ProductPrice";

export const ProductLink = ({ ...props }: ExtrasProductCardInterface & { testId: string }) => {
  const isProductList = props.isProductList ?? false;
  const isSlider = props.isSlider ?? false;
  return (
    <Link
      href={props.productDetailLink ?? ""}
      className={clsx("w-full", {
        "mt-auto": !isProductList && !isSlider,
      })}
      title={props.product.title}
      data-testid={`${props.testId}DetailsLink${props.product.id}`}
    >
      <div className={`pt-2 sm:pt-4 ${!props.smallText ? "px-1" : "px-0"}`}>
        <p className="text-ch21-darkgray text-xs lg:text-base uppercase" data-testid={`${props.testId}DetailsBrand${props.product.id}`}>
          {props.product.brand}
        </p>
        <div
          className={`font-bold text-ellipsis overflow-hidden line-clamp-2 ${!props.smallText ? "text-sm lg:text-xl" : "text-md"}`}
          data-testid={`${props.testId}DetailsName${props.product.id}`}
        >
          {props.product.title ?? ""}
        </div>
        <div className="text-xs lg:text-base text-ch21-darkgray" data-testid={`${props.testId}DetailsType${props.product.id}`}>
          {props.product.product_type_detail}
        </div>
      </div>

      <ProductCardPrice product={props.product} testId={props.testId} boldText={props.boldText} smallText={props.smallText} />
    </Link>
  );
};
