import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import React from "react";

interface Props {
  product: PricedProduct;
  className?: string;
  testId?: string;
}

interface LabelInterface {
  identifier: string;
  prefix: string;
  className: string;
}

interface Props {
  product: PricedProduct;
  className?: string;
  testId?: string;
}

const LABELS = [
  { identifier: "new", prefix: "Neu", className: "bg-ch21-flag-new" },
  { identifier: "online_only", prefix: "Online Only", className: "bg-ch21-color" },
  { identifier: "free_shipping", prefix: "Versandkostenfrei", className: "bg-ch21-green" },
] as LabelInterface[];

const LabelItem = ({ label, testId, product }: { label: LabelInterface } & Props) => (
  <div className={`px-3.5 py-0.5 w-fit uppercase text-xs lg:text-base ${label.className}`} data-testid={`${testId}${product.id}${label.identifier}`}>
    {label.prefix}
  </div>
);

const isValidLabel = (metadata: Record<string, unknown> | null | undefined, identifier: string): boolean => {
  const value = metadata?.[identifier];
  return value !== null && value !== false && value !== 0 && value !== "0" && value !== "false";
};

export default function ProductFlags({ product, className, testId = "productFlags" }: Props) {
  if (typeof product?.metadata !== "object") {
    return null;
  }

  return (
    <div className={`absolute text-white overflow-hidden grid gap-1.5 bottom-2.5 left-0 ${className}`} data-testid={`${testId}${product.id}`}>
      {LABELS.map((label) => {
        const hasLabel = Object.prototype.hasOwnProperty.call(product.metadata, label.identifier) && isValidLabel(product.metadata, label.identifier);

        return hasLabel ? <LabelItem key={`label-${label.identifier}`} label={label} product={product} testId={testId} /> : null;
      })}
    </div>
  );
}
