import clsx from "clsx";
import Link from "next/link";

import { ExtrasProductCardInterface } from "@/components/Product/ProductCard";
import ProductFlags from "@/components/Product/ProductFlags";
import { PlaceholderImageString, ProductImage } from "@/components/Product/ProductImage";
import ProductVariantLinks from "@/components/Product/ProductVariantLinks";

export const ProductHead = ({ ...props }: ExtrasProductCardInterface & { testId: string }) => {
  return (
    <div
      className={clsx(`w-full text-center relative flex justify-center items-center`, {
        "h-full": props.forceFullHeight || (!props.isProductList && !props.isSlider),
      })}
      data-testid={`${props.testId}Thumbnail`}
    >
      {props.productDetailLink && props.productThumbnail && (
        <Link
          href={props.productDetailLink}
          data-testid={`${props.testId}ThumbnailLink`}
          className={clsx(
            {
              "grid items-center justify-center": props.isProductList,
              "max-h-[425px]": props.isProductList && !props.maxHeight,
              "h-[56.9vw] sm:h-[38.9vw] lg:h-[25.9vw]": props.isProductList && props.gridCols !== 4,
              "h-[46.9vw] sm:h-[21.5vw] md:h-[20.5vw] lg:h-[15.9vw] 2xl:h-[12.5vw]": props.isProductList && props.gridCols === 4,
            },
            "w-full",
          )}
          style={typeof props.maxHeight === "number" ? { maxHeight: props.maxHeight } : undefined}
        >
          <div className={"absolute inset-0 bg-black opacity-[03%] z-10 pointer-events-none"}></div>
          <ProductImage
            src={props.productThumbnail}
            width={400}
            height={540}
            alt={props.product.title ?? ""}
            data-testid={`${props.testId}ThumbnailImage${props.product.id}`}
            fallbackimage={PlaceholderImageString}
            priority={props.priority}
            className={clsx({
              "w-full aspect-auto": !props.isProductList,
              "w-auto max-h-full mx-auto absolute left-0 right-0": props.isProductList,
            })}
          />
        </Link>
      )}
      {!props.productDetailLink && props.productThumbnail && (
        <ProductImage
          src={props.productThumbnail}
          width={400}
          height={540}
          alt={props.product.title ?? ""}
          fallbackimage={PlaceholderImageString}
          data-testid={`${props.testId}ThumbnailImage${props.product.id}`}
          priority={props.priority}
          className={clsx({
            "w-full aspect-auto": !props.isProductList,
            "w-auto max-h-full mx-auto": props.isProductList,
          })}
        />
      )}
      <ProductVariantLinks product={props.product} testId={props.testId} />
      <ProductFlags product={props.product} />
    </div>
  );
};
