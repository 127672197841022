import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import clsx from "clsx";
import React, { useCallback, useEffect, useId, useState } from "react";
import { Tooltip } from "react-tooltip";

import { HeartFillIcon } from "@/components/Icon/Heart/HeartFillIcon";
import { HeartLineIcon } from "@/components/Icon/Heart/HeartLineIcon";
import { useAccount } from "@/lib/contexts/account-context";
import { AUTHENTICATION_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";
import { useWishlist } from "@/lib/contexts/wishlist-context";

interface WishlistIconProps {
  product: PricedProduct;
  type?: "icon" | "text";
}

export const WishlistIcon = ({ product, type = "icon" }: WishlistIconProps) => {
  const { customer, refetchCustomer: refetch } = useAccount();
  const { wishlist, addProduct: addToWishlistMutation, removeProduct: removeFromWishlistMutation, isMutating } = useWishlist();
  const [isLiked, setLiked] = useState<boolean>(false);
  const id = useId();
  const { setModal } = useSideModal();

  useEffect(() => {
    setLiked(undefined !== wishlist?.items.find((lineItem) => lineItem.product_id === product.id));
  }, [wishlist, product]);

  const addToWishlist = useCallback(() => {
    if (!customer) {
      setModal({ name: AUTHENTICATION_MODAL, isShow: true });
      return;
    }

    addToWishlistMutation({ id: wishlist!.id, product_id: product.id! });
  }, [addToWishlistMutation, customer, product.variants]);

  const removeFromWishlist = useCallback(() => {
    if (!customer) {
      return;
    }

    const wishlistIndex = wishlist?.items.findIndex((lineItem) => lineItem.product_id === product.id);
    if (wishlistIndex === -1) {
      // may be stale content, force refetch to update state
      refetch();
      return;
    }

    if (wishlistIndex !== -1 && wishlistIndex !== undefined) {
      removeFromWishlistMutation({ id: wishlist!.id, product_id: wishlist!.items[wishlistIndex].product_id as string });
    }
  }, [customer, product.id, refetch, removeFromWishlistMutation, wishlist]);

  const renderLink = (isLiked: boolean) => {
    if (isLiked) {
      return (
        <div onClick={() => !isMutating && removeFromWishlist()} className={clsx({ "cursor-pointer": true }, { "animate-pulse": isMutating })}>
          Von Wunschzettel entfernen
        </div>
      );
    }

    return (
      <div onClick={() => !isMutating && addToWishlist()} className={clsx({ "cursor-pointer": true }, { "animate-pulse": isMutating })}>
        Auf den Wunschzettel
      </div>
    );
  };

  // product has no variants to add to wishlist
  if (product.variants?.length === 0) {
    return null;
  }

  if (type === "text") {
    return renderLink(isLiked);
  }

  if (isLiked) {
    return <HeartFillIcon width={25} fill={"#db2777"} onClick={() => !isMutating && removeFromWishlist()} className={isMutating ? "animate-pulse" : ""} />;
  }

  return (
    <>
      <HeartLineIcon width={25} onClick={() => !isMutating && addToWishlist()} className={clsx(isMutating ? "animate-pulse" : "", "hasTooltip-" + id)} />
      {!customer && <Tooltip anchorSelect={".hasTooltip-" + id}>Für die Wunschliste müssen Sie angemeldet sein.</Tooltip>}
    </>
  );
};
