import clsx from "clsx";
import React from "react";

import { BottomAlignment } from "@/components/Product/components/Alignment/BottomAlignment";
import { RightAlignment } from "@/components/Product/components/Alignment/RightAlignment";
import { ExtrasProductCardInterface, PRODUCT_TEXT_ALIGNMENT, ProductCardInterface } from "@/components/Product/ProductCard";

export const ProductCardContent = ({ ...props }: ExtrasProductCardInterface) => {
  switch (props.alignment) {
    default:
    case PRODUCT_TEXT_ALIGNMENT.BOTTOM:
      return <BottomAlignment {...props} />;
    case PRODUCT_TEXT_ALIGNMENT.RIGHT:
      return <RightAlignment {...props} />;
  }
};

export const ProductLayout = ({
  ...props
}: ProductCardInterface & { children: React.ReactNode; isProductList?: boolean; isSlider?: boolean; forceFullHeight?: boolean }) => {
  return (
    <div
      className={clsx(`h-full flex-col items-start flex group ${props.alignment?.valueOf() === PRODUCT_TEXT_ALIGNMENT.BOTTOM && (props.className ?? "")}`, {
        "content-end justify-between": !props.isProductList && !props.isSlider,
      })}
      onClick={props.onClick}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};
